import React from "react"
import { Link, graphql } from "gatsby"

import Post, { makeProps } from "../components/post"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio.js"
import LightSep from "../components/light-separator"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.post
  const { previous, next } = pageContext
  const postData = {
    path: post.fields.slug,
    published: post.metadata.published,
    modified: post.metadata.modified,
    author: post.metadata.author,
    tags: post.metadata.tags,
  }

  const postProps = makeProps({
    data,
    post,
    author: post.metadata.author || data.site.siteMetadata.author.name,
  })

  const overwriteImagePath = (staticImage, imgData) =>
    !!staticImage
      ? {
          ...imgData,
          image: staticImage.publicURL,
        }
      : null
  const twitter = overwriteImagePath(data.twitter, post.metadata.images.twitter)
  const openGraph = overwriteImagePath(
    data.openGraph,
    post.metadata.images.openGraph,
  )

  const Sep = () => (
    <div className="[ post-separator subgrid ]">
      <div className="content">
        <LightSep />
      </div>
    </div>
  )

  return (
    <Layout>
      <SEO
        title={post.metadata.title}
        description={post.metadata.description}
        twitterImage={twitter}
        openGraphImage={openGraph}
        postData={postData}
        canonicalLink={post.metadata.canonicalLink}
      />
      <div className="subgrid gap">
        <article className="[ span-all ]">
          <Post {...postProps} />
        </article>

        <Sep />

        <div className="[ span-all subgrid ]">
          <article className="[ content ]">
            <nav aria-labelledby="post-nav">
              <h2 id="post-nav">Post navigation: continue?</h2>
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {next && (
                    <Link to={next.fields.path} rel="next">
                      ← {next.metadata.title}
                    </Link>
                  )}
                </li>
                <li
                  style={{
                    flex: "auto",
                    textAlign: "right",
                  }}
                >
                  {previous && (
                    <Link to={previous.fields.path} rel="prev">
                      {previous.metadata.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
          </article>
        </div>

        <Sep />

        <div className="span-all subgrid">
          <Bio className="[ content ]" />
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $hero: String
    $twitter: String
    $openGraph: String
  ) {
    hero: file(absolutePath: { eq: $hero }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      extension
      publicURL
    }
    twitter: file(absolutePath: { eq: $twitter }) {
      publicURL
    }
    openGraph: file(absolutePath: { eq: $openGraph }) {
      publicURL
    }
    site {
      siteMetadata {
        title
        siteUrl
        author {
          name
        }
      }
    }
    post: orgContent(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        path
      }
      metadata {
        title
        subtitle
        description
        publishedPretty: published(formatString: "MMMM DD, YYYY")
        published
        modifiedPretty: modified(formatString: "HH:mm, MMMM DD, YYYY")
        modified
        hero_contain
        author
        hero_caption
        tags
        canonicalLink: canonical_link
        images {
          twitter {
            image
            altText
          }
          openGraph {
            image
            altText
          }
          hero {
            image
            altText
            attribution
          }
        }
      }
    }
  }
`
