import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import Metadata from "../components/metadata"
import { transformLinks } from "../utils/org"

export const makeProps = ({ data, post, author }) => {
  const hero = data.hero
    ? {
        ...post.metadata.images.hero,
        image: data.hero.childImageSharp,
        extension: data.hero.extension,
        svg: data.hero.publicURL,
      }
    : null

  const published = {
    iso: post.metadata.published,
    pretty: post.metadata.publishedPretty,
  }
  const modified = post.metadata.modified
    ? {
        iso: post.metadata.modified,
        pretty: post.metadata.modifiedPretty,
      }
    : undefined
  return {
    post,
    author,
    timestamps: { published, modified },
    hero,
  }
}

const TitleSection = ({
  title,
  subtitle,
  author,
  published,
  modified,
  tags,
  postLink,
}) => {
  return (
    <section className="[ header subgrid ]">
      <div className="[ content ]">
        <div className="[ heading ]">
          <div className="[ title-wrapper ]">
            <h1 className="title">
              <Link className="[ post-title ]" to={postLink}>
                {title}
              </Link>
            </h1>
            {subtitle && <small className="[ caption ]">{subtitle}</small>}
          </div>
        </div>

        <Metadata
          author={author}
          published={published}
          modified={modified}
          tags={tags}
        />
      </div>
    </section>
  )
}

const Article = ({ hero, heroCaption, heroContain, articleContent }) => {
  const heroContainerClass = heroContain ? "content" : "span-all"

  const Hero = () => {
    const Img = () => {
      if (hero.extension === "svg") {
        return (
          <img
            src={hero.svg}
            alt={hero.altText}
            className={`[ hero ][ ${heroContainerClass} ]`}
          />
        )
      } else {
        return (
          <Image
            fluid={hero.image.fluid}
            alt={hero.altText}
            className={`[ hero ${heroContainerClass} ]`}
          />
        )
      }
    }
    return (
      <div className="[ subgrid hero-container ]">
        <Img />
        {heroCaption && (
          <p
            className="[ content caption ]"
            dangerouslySetInnerHTML={{ __html: transformLinks(heroCaption) }}
          />
        )}
      </div>
    )
  }
  return (
    <section className="[ subgrid ]">
      {hero && <Hero />}
      <div
        className="[ content body post-body ]"
        dangerouslySetInnerHTML={{ __html: transformLinks(articleContent) }}
      />
    </section>
  )
}

export default function({
  post,
  author,
  timestamps: { published, modified },
  hero,
}) {
  return (
    <article className="[ span-all ]">
      <TitleSection
        title={post.metadata.title}
        subtitle={post.metadata.subtitle}
        author={author}
        published={published}
        modified={modified}
        tags={post.metadata.tags}
        postLink={"/" + post.fields.path}
      />
      <Article
        hero={hero}
        heroCaption={post.metadata.hero_caption}
        heroContain={post.metadata.hero_contain}
        articleContent={post.html}
      />
    </article>
  )
}
